import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import { AuthContext } from 'src/component/Context/AuthContext'

import HeaderBar from '@/component/Landmark/HeaderBar'
import { logout } from 'src/lib/auth-api'

export default function Header() {
  const router = useRouter()
  const { auth, dispatch } = useContext(AuthContext)
  const handleLogout = async () => {
    await logout()
    dispatch({ type: 'LOGOUT' })
  }

  const paths = [
    {
      to: '/favourites',
      text: 'Favourites',
      icon: 'heart'
    }
    // {
    //   to: '/valuation',
    //   text: 'Valuations',
    //   icon: 'car'
    // }
  ]

  const userPaths = [
    // {
    //   url: '#account',
    //   text: 'My account'
    // },
    {
      onClick: handleLogout,
      text: 'Log out'
    }
  ]

  const activePaths = paths.map((path) => {
    path.active = router.asPath === path.to || router.asPath === path.href

    return path
  })

  return (
    <HeaderBar
      {...(auth.user && {
        user: { firstName: auth.user.firstName, lastName: auth.user.lastName }
      })}
      items={activePaths}
      userItems={userPaths}
      loginPath="/auth/login"
    />
  )
}
