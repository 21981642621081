import GridWrapper from './component/GridWrapper'
import GridItem from './component/GridItem'

/**
 * A simple inline-block based responsive grid, with two possible
 * interfaces for creating column widths.
 */

const Grid = GridWrapper
Grid.displayName = 'Grid'

Grid.Item = GridItem
Grid.Item.displayName = 'Grid.Item'

export default Grid
